import React from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Box, CssBaseline, styled} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';


const Main = styled(Box)(({theme}) => ({
  maxWidth: '600px',
  margin: '0 auto'
}))

const ToolbarCustom = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: '0 4px',
  boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.23)',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.contrastText
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '600px',
    margin: '0 auto',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    padding: '0',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    }
  },
}));

const Title = styled('h3')({
  display: 'block',
  margin: '0 auto'
})

function AppModal(prop: {title: string}) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
        <CssBaseline />
        <ToolbarCustom>
          <IconButton aria-label="Назад" size="large" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Title>{prop.title}</Title>
          <IconButton sx={{visibility: 'hidden'}} size="large">
            <EditIcon />
          </IconButton>
        </ToolbarCustom>
        <Main>
          <Outlet />
        </Main>
    </>
  );
}

export default AppModal;