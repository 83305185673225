const URI = '/api/v1/';
const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export interface Product {
    uid: string;
    name: string;
    image: string;
    link: string;
    comment: string;
}

export interface Collection {
    uid: string;
    name: string | null;
    published: boolean;
    forbidBooking: boolean;
    products: {uid: string}[];
}

export interface View {
    name: string | null;
    self: boolean;
    products: ViewProduct[];
}

export enum BookingStatus {
    NONE = 'none',
    SELF = 'self',
    OTHER = 'other',
    FORBID = 'forbid'
}

export enum RequestKeys {
    listProduct = 'listProduct',
    listCollection = 'listCollection',
    getProduct = 'getProduct',
    getCollection = 'getCollection',
    getView = 'getView',
    getViewProduct = 'getViewProduct'
}

export type ViewProduct = Product & {bookingStatus: BookingStatus};

export type InputProduct = Partial<Omit<Product, 'uid'>>;
export type InputCollection = Partial<Omit<Collection, 'uid'>>;


export class ApiService {
    private static instance: ApiService | null = null;

    get accessToken(): string | null {
        return localStorage.getItem('token') || null
    }

    constructor() {
        if (ApiService.instance)
            return ApiService.instance;
        
        ApiService.instance = this;
    }

    private get headersWithToken(): Record<string, string> {
        if (!this.accessToken) return {...HEADERS};

        return {
            ...HEADERS,
            'Authorization': 'Bearer ' + this.accessToken
        };
    }

    async getToken(type: 'ANONYMOUS'): Promise<boolean>{
        const response = await fetch(URI + 'token', {
            method: 'POST',
            headers: HEADERS,
            body: JSON.stringify({type})
        });

        if (!response.ok) throw response;

        const json = await response.json();
        
        localStorage.setItem('token', json.accessToken);

        return response.ok;
    }

    async sendProduct(data: InputProduct): Promise<Product>{
        const response = await fetch(URI + 'products', {
            method: 'POST',
            headers: this.headersWithToken,
            body: JSON.stringify(data)
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async updateProduct(id: string, data: InputProduct): Promise<Product>{
        const response = await fetch(URI + 'products/' + id, {
            method: 'PUT',
            headers: this.headersWithToken,
            body: JSON.stringify(data)
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async deleteProduct(id: string) {
        const response = await fetch(URI + 'products/' + id, {
            method: 'DELETE',
            headers: this.headersWithToken,
        });
        
        if (!response.ok) throw response;

        return response.ok;
    }

    async getProduct(id: string): Promise<Product>{
        const response = await fetch(URI + 'products/' + id, {
            method: 'GET',
            headers: this.headersWithToken,
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async listProduct(): Promise<Product[]>{
        const response = await fetch(URI + 'products', {
            method: 'GET',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async sendCollection(data: InputCollection): Promise<Collection>{
        const response = await fetch(URI + 'collections', {
            method: 'POST',
            headers: this.headersWithToken,
            body: JSON.stringify(data)
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async getCollection(id: string): Promise<Collection>{
        const response = await fetch(URI + 'collections/' + id, {
            method: 'GET',
            headers: this.headersWithToken
        });
    
        if (!response.ok) throw response;

        const json = await response.json();
        

        return json;
    }

    async listCollection(): Promise<Collection[]>{
        const response = await fetch(URI + 'collections', {
            method: 'GET',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async updateCollection(id: string, data: InputCollection): Promise<Collection>{
        const response = await fetch(URI + 'collections/' + id, {
            method: 'PUT',
            headers: this.headersWithToken,
            body: JSON.stringify(data)
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async deleteCollection(id: string) {
        const response = await fetch(URI + 'collections/' + id, {
            method: 'DELETE',
            headers: this.headersWithToken,
        });
        
        if (!response.ok) throw response;

        return response.ok;
    }

    async deleteCollectionReset(id: string) {
        const response = await fetch(URI + 'collections/' + id + '/reset', {
            method: 'DELETE',
            headers: this.headersWithToken,
        });

        if (!response.ok) throw response;

        return response.ok;
    }

    async getView(id: string): Promise<View>{
        const response = await fetch(URI + 'view/' + id, {
            method: 'GET',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async postViewProduct(collectionId: string, productId: string): Promise<boolean>{
        const response = await fetch(URI + 'view/' + collectionId + '/products/' + productId, {
            method: 'POST',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;
        
        return response.ok;
    }

    async getViewProduct(collectionId: string, productId: string): Promise<ViewProduct>{
        const response = await fetch(URI + 'view/' + collectionId + '/products/' + productId, {
            method: 'GET',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async deleteViewProduct(collectionId: string, productId: string): Promise<boolean>{
        const response = await fetch(URI + 'view/' + collectionId + '/products/' + productId, {
            method: 'DELETE',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        return response.ok;
    }

    async getSetting(): Promise<{name?: string}> {
        const response = await fetch(URI + 'setting', {
            method: 'GET',
            headers: this.headersWithToken
        });

        if (!response.ok) throw response;

        const json = await response.json();

        return json;
    }

    async putSetting(data: {name?: string}) {
        const response = await fetch(URI + 'setting', {
            method: 'PUT',
            headers: this.headersWithToken,
            body: JSON.stringify(data)
        });

        if (!response.ok) throw response;

        return response.ok;
    }
}