import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {Button, ButtonGroup, TextField} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useParams, useNavigate} from 'react-router-dom';
import {ApiService, RequestKeys} from '../api.service';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {openToast} from '../index';


const Image = styled('div')({
  margin: '0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  aspectRatio: '1 / 1',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  backgroundColor: '#FFF',
  position: 'sticky',
  top: '0',
  zIndex: '1'
});

const EmptyImage = styled('div')({
  margin: '16px',
  padding: '16px',
  aspectRatio: '1 / 1',
  border: '3px dashed rgba(0,0,0,0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  position: 'sticky',
  top: '16px',
  zIndex: '1',
  '& :first-of-type': {
    borderBottom: '2px dashed rgba(0,0,0,0.2)'
  },
  '&.error': {
    borderColor: 'red'
  }
});

const EmptyImageLabel = styled('h2')({
  color: 'rgba(0,0,0,0.2)',
  margin: '0',
  display: 'flex',
  flex: '1',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column'
});

const ControlBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
  zIndex: '2',
  backgroundColor: theme.palette.background.default
}));

function fileToBase64(file: File | Blob): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => res(String(reader.result));
    reader.onerror = (error) => rej(error);
  })
}

function ProductItem() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const api = new ApiService();

  const fileInput = useRef<any>(null);

  const [name, setName] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [link, setLink] = useState<string>('');

  const [markError, setMarkError] = useState<boolean>(false);
  
  const { id } = useParams<{id: any}>();

  const isNew = !id || id === 'new';

  const query = useQuery(
    RequestKeys.getProduct + id,
    () => api.getProduct(id),
    {enabled: !isNew}
  );

  useEffect(() => {
      setName(query.data?.name ?? '');
      setImage(query.data?.image ?? '');
      setComment(query.data?.comment ?? '');
      setLink(query.data?.link ?? '');
  }, [query.data]);


  const mutationUpdate = useMutation(
    (data: any) => api.updateProduct(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.getProduct + id);
        queryClient.invalidateQueries(RequestKeys.listProduct);
        navigate('/');
      }
    }
  );

  const mutationCreate = useMutation(
    (data: any) => api.sendProduct(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.listProduct);
        navigate('/');
      }
    }
  );

  const mutationDelete = useMutation(
    (data: any) => api.deleteProduct(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.listProduct);
        navigate('/');
      }
    }
  );

  const handleSend = async () => {
    if (!name) {
      setMarkError(true);
      return;
    }

    setMarkError(false);

    if (isNew)
      mutationCreate.mutate({name, image, comment, link});

    else
      mutationUpdate.mutate({name, image, comment, link});
  }

  const handleDelete = async () => {
    if (!isNew)
      mutationDelete.mutate({});
  }

  const handleDownloadImage = async (event: any) => {
    const file: File = event.target.files[0];

    if (file.size > 5 * 1024 * 1024) {
      openToast('Максимальный размер файла 5мб');
      return;
    }
    
    const base64 = await fileToBase64(file);
    setImage(base64);
    event.target.value = '';
  }

  const handlePastImage = async (event: any) => {
    const auth = await navigator.permissions.query({name: 'clipboard-read'} as any);

    if(auth.state === 'denied') return;

    const itemList = await navigator.clipboard.read();
    let curentType = '';

    const item = itemList.find(item => 
      item.types.some(type => {
        if( type.startsWith( 'image/' ) ) {
          curentType = type;
          return true;
        }
      })
    );
    
    const file = item && await item.getType(curentType);

    if (file) {
      const base64 = await fileToBase64(file);
      setImage(base64);
    }
  }

  return (
    <Box>
      {
        (!query.isError && (!query.isLoading || isNew)) &&
        <Form>
          {
            image ? (
                <Image style={{backgroundImage: `url(${image})`}}>
                  <ButtonGroup variant="text" disableElevation style={{marginBottom: '8px'}}>
                    <Button variant="contained" disableElevation onClick={e => fileInput.current.click()}><UploadIcon/></Button>
                    <Button variant="contained" disableElevation onClick={e => handlePastImage(e)}><ContentPasteIcon/></Button>
                    <Button variant="contained" disableElevation onClick={e => setImage('')}><DeleteForeverIcon/></Button>
                  </ButtonGroup>
                </Image>
              ) : (
                <EmptyImage>
                  <EmptyImageLabel onClick={e => fileInput.current.click()}>Нажимите чтобы загрузить картинку</EmptyImageLabel>
                  <EmptyImageLabel onClick={e => handlePastImage(e)}>Или нажимите что бы вставте её из буфера обмена</EmptyImageLabel>
                </EmptyImage>
              )
          }

          <input ref={fileInput} accept="image/*" onChange={e => handleDownloadImage(e)} type="file" style={{display: 'none'}} />
          
          <ControlBox>
            <TextField
              required
              error={markError}
              label="Название"
              variant="outlined"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />

            <TextField
              label="Ссылка на подарок"
              variant="outlined"
              value={link}
              onChange={(e: any) => setLink(e.target.value)}
            />

            <TextField
              label="Коментарий"
              multiline
              rows={4}
              variant="outlined"
              value={comment}
              onChange={(e: any) => setComment(e.target.value)}
            />
          
            <Button variant="contained" onClick={handleSend}>Сохранить</Button>
            {
              !isNew && <Button color="warning" variant="contained" onClick={handleDelete}>Удалить</Button>
            }
          </ControlBox>
        </Form>
      }
    </Box>
  );
}

export default ProductItem;