import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useNavigate, useParams} from 'react-router-dom';
import {ApiService, BookingStatus, Product, RequestKeys, View, ViewProduct} from '../api.service';
import {useEffect} from 'react';
import {useState} from 'react';
import ProductCard from '../share/ProductCard';
import {Button, TextField} from '@mui/material';
import {generateGradientByString} from '../share/utils';
import {useMutation, useQuery, useQueryClient} from 'react-query';


const Image = styled('div')({
  margin: '0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  aspectRatio: '1 / 1',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  backgroundColor: '#FFF',
  position: 'sticky',
  top: '0',
  zIndex: '1'
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column'
});

const LineBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
  zIndex: '2',
  backgroundColor: theme.palette.background.default
}));

const Line = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const LineLabel = styled('div')({
  fontWeight: '600',
  fontSize: '0.75em'
});

const LineDescription = styled('div')({
});

const Link = styled('a')({
  textDecoration: 'none',
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
})

function ViewItem() {
  const api = new ApiService();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [bookingStatus, setBookingStatus] = useState<BookingStatus>();
  
  const [link, setLink] = useState<string>('');

  const { collectionId, id } = useParams<{id: any, collectionId: any}>();

  const query = useQuery(
    RequestKeys.getViewProduct + id,
    () => api.getViewProduct(collectionId, id)
  );

  useEffect(() => {
    const product = query.data;
    setName(product?.name ?? '');
    setImage(product?.image ?? '');
    setComment(product?.comment ?? '');
    setLink(product?.link ?? '');
    setBookingStatus(product?.bookingStatus);
  }, [query.data]);

  const mutationDeleteViewProduct = useMutation(
    (data: any) => api.deleteViewProduct(collectionId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.getView + collectionId);
        query.refetch();
      }
    }
  );

  const mutationViewProduct = useMutation(
    (data: any) => api.postViewProduct(collectionId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.getView + collectionId);
        query.refetch();
      }
    }
  );

  const handleMark = async () => {
    if (bookingStatus === BookingStatus.SELF)
      mutationDeleteViewProduct.mutate({});

    else if (bookingStatus === BookingStatus.NONE)
      mutationViewProduct.mutate({});
  }

  const getTextButton = (bookingStatus: BookingStatus): string => {
    switch (bookingStatus) {
      case BookingStatus.SELF:
        return 'Оменить бронирование'

      case BookingStatus.OTHER:
        return 'Уже забронировано'

      default:
        return 'Забронировать'
    }
  }

  return (
    <Box>
      {
        !query.isError && !query.isLoading &&
        <Form>
        {
          image ?
            <Image style={{backgroundImage: `url(${image})`}}></Image> :
            <Image style={{backgroundImage: generateGradientByString(name)}}></Image>
        }
 
        <LineBox>
          {
            name && (
              <Line>
                <LineLabel>
                  Название
                </LineLabel>
                <LineDescription>
                  {name}
                </LineDescription>
              </Line>
            )
          }

          {
            link && (
              <Line>
                <LineLabel>
                  Ссылка на подарок
                </LineLabel>
                <LineDescription>
                  <Link target="_blank" href={link}>{link}</Link>
                </LineDescription>
              </Line>
            )
          }

          {
            comment && (
              <Line>
                <LineLabel>
                  Коментарий
                </LineLabel>
                <LineDescription>
                  {comment}
                </LineDescription>
              </Line>
            )
          }

            <Button
              variant="outlined"
              href={'https://market.yandex.ru/search?cvredirect=1&text=' + encodeURI(name)}
              target="_blank"
            >
              Найти в Яндекс.Маркет
            </Button>

            <Button
              variant="outlined"
              href={'https://www.ozon.ru/search/?from_global=true&text=' + encodeURI(name)}
              target="_blank"
            >
              Найти в Ozon
            </Button>

            <Button
              variant="outlined"
              href={'https://www.wildberries.ru/catalog/0/search.aspx?search=' + encodeURI(name)}
              target="_blank"
            >
              Найти в Wildberries
            </Button>

            <Button
              variant="outlined"
              href={'https://aliexpress.ru/wholesale?SearchText=' + encodeURI(name)}
              target="_blank"
            >
              Найти в AliExpress
            </Button>

            {
              bookingStatus !== BookingStatus.FORBID &&
              <Button
                variant="contained"
                color={bookingStatus === BookingStatus.SELF ? 'warning' : 'primary'}
                disabled={bookingStatus === BookingStatus.OTHER}
                onClick={handleMark}
              >
                {getTextButton(bookingStatus!)}
              </Button>
            }

        </LineBox>
      </Form>
      }
    </Box>
  );
}

export default ViewItem;