import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {Button, Checkbox, FormControlLabel, TextField} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {ApiService, Product, RequestKeys} from '../api.service';
import ProductCard from '../share/ProductCard';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {openToast} from '../index';


const Wrapper = styled(Box)({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '1fr 1fr 1fr'
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px'
});


function CollectionItem() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const api = new ApiService();

  const [name, setName] = useState<string>('');
  const [published, setPublished] = useState<boolean>(false);
  const [forbidBooking, setForbidBooking] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  const [markError, setMarkError] = useState<boolean>(false);

  const { id } = useParams<{id: any}>();

  const isNew = !id || id === 'new';

  const queryProducts = useQuery(
    RequestKeys.listProduct,
    () => api.listProduct()
  );

  const queryCollections = useQuery(
    RequestKeys.getCollection + id,
    () => api.getCollection(id),
    {enabled: !isNew}
  );

  const isLoading = queryProducts.isLoading || queryCollections.isLoading;
  const isError = queryProducts.isError || queryCollections.isError;

  useEffect(() => {
    const data = queryCollections.data;
    const collectionProducts = data?.products ?? [];

    setName(data?.name ?? '');
    setPublished(data?.published ?? false);
    setForbidBooking(data?.forbidBooking ?? false);
    setSelectedProductIds(collectionProducts.map(i => i.uid));

    const products = queryProducts.data ?? [];

    setProducts(
      products.sort(i => collectionProducts.map(i => i.uid).includes(i.uid) ? -1 : 1)
    );

  }, [queryCollections.data, queryProducts.data]);

  const mutationCreate = useMutation(
    (data: any) => api.sendCollection(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.listCollection);
        navigate('/collections');
      }
    }
  );

  const mutationUpdate = useMutation(
    (data: any) => api.updateCollection(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.getCollection + id);
        queryClient.invalidateQueries(RequestKeys.listCollection);
        navigate('/collections');
      }
    }
  );

  const mutationDelete = useMutation(
    (data: any) => api.deleteCollection(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RequestKeys.listCollection);
        navigate('/collections');
      }
    }
  );

  const mutationReset = useMutation(
    (data: any) => api.deleteCollectionReset(id),
    {
      onSuccess: () => {
        openToast('Бронирования успешно сброшенны');
      }
    }
  );


  const handleSend = async () => {
    if (!name) {
      setMarkError(true);
      return;
    }

    setMarkError(false);

    if (isNew)
      mutationCreate.mutate({
        name,
        published: published,
        forbidBooking: forbidBooking,
        products: selectedProductIds.map(i => ({uid: i}))
      });

    else
      mutationUpdate.mutate({
          name,
          published: published,
          forbidBooking: forbidBooking,
          products: selectedProductIds.map(i => ({uid: i}))
      });
  }

  const handleDelete = async () => {
    if (!isNew)
      mutationDelete.mutate({});
  }

  const handleSelectProduct = (selectId: string) => {
    if (selectedProductIds.includes(selectId))
      setSelectedProductIds([...selectedProductIds.filter(i => i !== selectId)]);

    else
      setSelectedProductIds([...selectedProductIds, selectId]);
  }

  const handleCopyLink = () => {
    // eslint-disable-next-line no-restricted-globals
    navigator.clipboard.writeText(location.host + '/view/' + id);
  }

  const handleBookingReset = async () => {
    if (!isNew)
      mutationReset.mutate({});
  }

  return (
    <Box>
      {
        (!isError && (!isLoading || isNew)) &&
        <Form>
          <TextField
            required
            error={markError}
            label="Название"
            variant="outlined"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={published}
                onChange={(e: any) => setPublished(e.target.checked)}
              />
            }
            label="Опубликовать"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={forbidBooking}
                onChange={(e: any) => setForbidBooking(e.target.checked)}
              />
            }
            label="Запретить бронирование"
          />

          {
            !isNew && published &&
            <Button variant="contained" onClick={handleCopyLink}>Скопировать ссылку</Button>
          }

          <Button variant="contained" onClick={handleSend}>Сохранить</Button>

          {
            !isNew &&
            <Button color="warning" variant="contained" onClick={handleBookingReset}>Сбросить бронирования</Button>
          }
          
          {
            !isNew && <Button color="warning" variant="contained" onClick={handleDelete}>Удалить</Button>
          }

          <h3>Выберите, что добавить в коллекцию:</h3>

          <Wrapper>
          {
            products
              .map((item, index) =>
                <ProductCard
                  key={index}
                  product={item}
                  size="small"
                  mark={selectedProductIds.includes(item.uid)}
                  onClick={() => handleSelectProduct(item.uid)}
                />
              )
          }
          </Wrapper>
        </Form>
      }
    </Box>
  );
}

export default CollectionItem;