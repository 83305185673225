import React from 'react';
import {styled} from '@mui/material/styles';
import {Product} from '../api.service';
import {ButtonBase} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {generateGradientByString} from './utils';

const Item = styled(ButtonBase)({
  display: 'flex',
  border: 'none',
  background: '#FFF',
  font: 'inherit',
  padding: '0px',
  flexDirection: 'column',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0 0 5px rgb(0, 0, 0, 0.1)',
  fontSize: '1rem',
  justifyContent: 'normal',
  gap: '1em',
  '&[data-mark-blur=true]::before': {
    content: '""',
    position: 'absolute',
    zIndex: 99,
    background: 'rgb(255,255,255, 0.5)',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  }
});

const ItemMark = styled('div')({
  position: 'absolute',
  right: '0.5rem',
  top: '0.5rem',
  zIndex: 101
})

const Title = styled('div')({
  fontWeight: '500',
  padding: '0 1em 1em 1em',
  lineHeight: '1em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'left',
  fontSize: 'inherit'
});

const Image = styled('div')(({theme}) => ({
  margin: '0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  aspectRatio: '1 / 1'
}));

const smallTitleStyle = {
  fontSize: '0.75rem'
}

const Booking = styled('div')({
  position: 'absolute',
  top: '8px',
  right: '8px'
});

interface ProductCardProps {
  product: Product,
  mark?: boolean,
  booking?: boolean,
  onClick: any,
  style?: any,
  size?: 'small' | 'large',
};

function ProductCard(props: ProductCardProps) {
  const {product, size, style, mark, onClick, booking} = props;

  return (
    <Item
      data-mark={mark}
      data-mark-blur={(size === 'small' && !booking && !mark) || (booking && mark)}
      type="button"
      style={style}
      onClick={onClick}
      sx={
        size === 'small' ? smallTitleStyle : {}
      }
    >
      {
        mark && !booking && <ItemMark>
          <CheckCircleOutlineIcon color="primary" fontSize="large" style={{position: 'relative', zIndex: 101}} />
          <div style={{position: 'absolute', zIndex: 100, top: '4px', right: '4px', borderRadius: '100%', width: '27px', height: '27px', backgroundColor: '#FFF'}}></div>
        </ItemMark>
      }
        {
          product.image ?
            <Image sx={{backgroundImage: `url(${product.image})`}}>&nbsp;</Image> :
            <Image sx={{backgroundImage: generateGradientByString(product.name)}}>&nbsp;</Image>
        }
        <Title>{product.name}</Title>
        {
          mark && booking &&
          <Booking>
            <LockIcon color="primary" style={{position: 'relative', zIndex: 101}} />
            <div style={{position: 'absolute', zIndex: 100, top: '-2px', right: '-2px', borderRadius: '100%', width: '30px', height: '30px', backgroundColor: '#FFF'}}></div>
          </Booking>
        }
    </Item>
  );
}

export default ProductCard;
export const ProductCardStyle = Item;