import React, {useEffect, useState} from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';

import {styled} from '@mui/material/styles';
import {Divider, Paper} from '@mui/material';
import {ApiService} from './api.service';


const BottomNavigationCustom = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main
}));

const BottomNavigationActionCustom = styled(BottomNavigationAction)(({ theme }) => ({
  color: '#d7c7fe', 
  '&.Mui-selected': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText
    }
  }
})) as any;

const ContentBox = styled(Box)(({theme}) => ({
  paddingBottom: 56,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 0
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 auto',
    maxWidth: '1440px'
  }
}))

const Navigation = styled(Paper)(({theme}) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))


function App() {
  const api = new ApiService();

  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname);
  

  useEffect(() => {
    !api.accessToken && navigate('/login');
  }, [])

  return (
    <ContentBox>
      <CssBaseline />
      <Outlet />
      <Navigation elevation={0}>
        <Divider />
        <BottomNavigationCustom
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationActionCustom
            icon={<HomeIcon />}
            component={Link}
            to="/"
            value="/"
          />
          <BottomNavigationActionCustom
            icon={<AppsIcon />}
            component={Link}
            to="/collections"
            value="/collections"
          />
        </BottomNavigationCustom>
      </Navigation>
    </ContentBox>
  );
}

export default App;
