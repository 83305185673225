import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import {ApiService, Product, RequestKeys} from '../api.service';
import {useEffect} from 'react';
import {useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ProductCard, {ProductCardStyle} from '../share/ProductCard';
import {useQuery} from 'react-query';


const Wrapper = styled(Box)(({theme}) => ({
  display: 'grid',
  gap: '8px',
  padding: '16px',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.up('sm')]: {
    gap: '16px',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '32px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  }
}));

const AddItem = styled(ProductCardStyle)({
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 4px #9E83DE',
  backgroundColor: 'transparent',
  aspectRatio: '1 / 1',
  padding: '1.5em 0',
  boxSizing: 'content-box'
});

function ProductList() {
  const api = new ApiService();

  const navigate = useNavigate();

  const queryProducts = useQuery(
    RequestKeys.listProduct,
    () => api.listProduct()
  );

  return (
    <Wrapper>
      <AddItem onClick={() => navigate('/products/new')}>
        <AddIcon color="primary" sx={{fontSize: '4rem'}} />
      </AddItem>
      {
        queryProducts.data?.map((item, index) =>
          <ProductCard key={index} product={item} onClick={() => navigate('/products/' + item.uid)} />
        )
      }
    </Wrapper>
  );
}

export default ProductList;