import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useNavigate, useParams} from 'react-router-dom';
import {ApiService, BookingStatus, Product, RequestKeys, View, ViewProduct} from '../api.service';
import {useEffect} from 'react';
import {useState} from 'react';
import ProductCard from '../share/ProductCard';
import {CssBaseline} from '@mui/material';
import {useQuery} from 'react-query';

const Wrapper = styled(Box)({
  display: 'grid',
  gap: '16px',
  padding: '16px',
  gridTemplateColumns: '1fr 1fr 1fr'
});

function ViewList() {
  const api = new ApiService();

  const navigate = useNavigate();
  const [data, setData] = useState<View>();

  const { id } = useParams<{id: any}>();

  const query = useQuery(
    RequestKeys.getView + id,
    () => api.getView(id)
  );

  useEffect(() => {
    setData(query.data);
  }, [query.data]);

  return (
    <>
      <h1 style={{margin: '21px 16px'}}>{data?.name}</h1>
      {data?.self && <div style={{margin: '0 16px'}}>Это ваши подарки, вы не можете их забронировать</div>}
      <Wrapper>
        {
          data?.products.map((item, index) =>
            <ProductCard
              key={index}
              mark={
                item.bookingStatus !== BookingStatus.FORBID &&
                [BookingStatus.SELF, BookingStatus.OTHER].includes(item.bookingStatus)
              }
              booking={true}
              product={item}
              onClick={() => navigate('product/' + item.uid)}
            />
          )
        }
      </Wrapper>
    </>
  );
}

export default ViewList;