export function generateGradientByString(text: string) {
    let hash = 0;

    if (!text) text = Math.random() + '';

    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    const str = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    const color = "00000".substring(0, 6 - str.length) + str;

    // eslint-disable-next-line no-mixed-operators
    const istr = (parseInt(color, 16) ^ 0xFFFFFF | 0x1000000).toString(16).substring(1).toUpperCase();
    const invertColor = "00000".substring(0, 6 - istr.length) + istr;

    return `linear-gradient(315deg, #${invertColor + '99'} 0%, #${color + '99'} 100%)`;
}