import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import {ApiService, Collection, Product, RequestKeys} from '../api.service';
import {useEffect} from 'react';
import {useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import {ButtonBase} from '@mui/material';
import {generateGradientByString} from '../share/utils';
import {useQuery} from 'react-query';

const Wrapper = styled(Box)(({theme}) => ({
  display: 'grid',
  gap: '16px',
  padding: '16px',
  [theme.breakpoints.up('sm')]: {
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '32px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  }
}));

const Item = styled(ButtonBase)({
  border: 'none',
  background: '#FFF',
  font: 'inherit',
  padding: '8px 10px',
  borderRadius: '8px',
  minHeight: '100px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden',
  boxShadow: '0 0 5px rgb(0, 0, 0, 0.1)'
});

const AddItem = styled(Item)({
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 4px #9E83DE',
  background: 'transparent'
});

const ProductList = styled('div')({
  display: 'flex',
  gap: '16px',
  minHeight: '60px',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  '::after': {
    content: '""',
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    height: '60px',
    width: '60px',
    background: 'linear-gradient(90deg, #FFF0 0%, #FFFF 100%)'
  }
});

const ProductItem = styled('div')({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '60px',
  width: '60px',
  aspectRatio: '1 / 1'
});

const ItemHeader = styled('div')({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%'
});

const Title = styled('b')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  padding: '0',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'left'
});

function CollectionList() {
  const api = new ApiService();

  const navigate = useNavigate();

  const queryCollections = useQuery(
    RequestKeys.listCollection,
    () => api.listCollection()
  );
  
  const queryProducts = useQuery(
    RequestKeys.listProduct,
    () => api.listProduct()
  );

  return (
    <Wrapper>
      <AddItem onClick={() => navigate('/collections/new')}>
        <AddIcon color="primary" sx={{fontSize: '3rem'}} />
      </AddItem>
      {
        queryCollections.data?.map((item, index) =>
          <Item key={index} onClick={() => navigate('/collections/' + item.uid)}>
            <ItemHeader>
              <Title>{item.name}</Title>
              {
                item.published && 
                <PeopleIcon color="primary" />
              }
            </ItemHeader>
            <ProductList>
              {
                queryProducts.data?.filter(prod => item.products.map(i => i.uid).includes(prod.uid))
                  .map((prod, ind) => 
                    prod.image ?
                      <ProductItem key={ind} sx={{backgroundImage: `url(${prod.image})`}} /> :
                      <ProductItem key={ind} sx={{backgroundImage: generateGradientByString(prod.name)}} />
                  )
              }
            </ProductList>
          </Item>
        )
      }
    </Wrapper>
  );
}

export default CollectionList;