import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';


function ProgressContainer() {
  return (
      <Backdrop
        sx={(thm) => ({ color: thm.palette.primary.main, background: '#ffffff77', zIndex: 100500 })}
        open={true}
      >
        <CircularProgress thickness={5} size={60} color="inherit" />
      </Backdrop>
  );
}

export default ProgressContainer;