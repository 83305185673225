import React from 'react';
import {Outlet} from 'react-router-dom';
import {Box, CssBaseline, styled} from '@mui/material';



const ContentBox = styled(Box)(({theme}) => ({
  paddingBottom: 7,
  [theme.breakpoints.up('lg')]: {
    margin: '0 auto',
    maxWidth: '1440px'
  }
}))

function AppPublic() {
  return (
    <ContentBox>
      <CssBaseline />
      <Outlet />
    </ContentBox>
  );
}

export default AppPublic;