import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import ProductList from './product/ProductList';
import {ThemeProvider} from '@emotion/react';
import {Box, createTheme, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, styled, Toolbar} from '@mui/material';
import ProductItem from './product/ProductItem';
import CollectionList from './collection/CollectionList';
import CollectionItem from './collection/CollectionItem';
import AppModal from './AppModal';
import ViewList from './view/ViewList';
import AppPublic from './AppPublic';
import ViewItem from './view/ViewItem';
import {ApiService} from './api.service';
import {MutationCache, QueryCache, QueryClient, QueryClientProvider, useIsFetching, useIsMutating, useQueries, useQueryClient} from 'react-query';
import ProgressContainer from './share/ProgressContainer';
import { ToastContainer, toast, Slide } from 'react-toastify';
import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from './error-page/ErrorPage';


const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: '#FFF1E2',
      default: '#FFF1E2'
    },
    primary: {
      main: '#9E83DE',
      contrastText: '#FFF'
    }
  }
});

const router = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <ProductList />,
      },
      {
        path: "/collections",
        element: <CollectionList />,
      }
    ],
  },
  {
    path: "/products",
    element: <AppModal title="Подарок" />,
    children: [
      {
        path: ":id",
        element: <ProductItem />
      }
    ]
  },
  {
    path: "/collections",
    element: <AppModal title="Группа" />,
    children: [
      {
        path: ":id",
        element: <CollectionItem />
      }
    ]
  },
  {
    path: "/",
    element: <AppPublic />,
    children: [
      {
        path: "/view/:id",
        element: <ViewList />
      }
    ]
  },
  {
    path: "/view/:collectionId/product/",
    element: <AppModal title="Подарок" />,
    children: [
      {
        path: ":id",
        element: <ViewItem />
      }
    ]
  },
  {
    path: "/403",
    element: <AppPublic />,
    children: [
      {
        path: "",
        element: <ErrorPage code={403} />,
      }
    ]
  },
  {
    path: "*",
    element: <AppPublic />,
    children: [
      {
        path: "*",
        element: <ErrorPage code={404} />,
      }
    ]
  },
];


if (localStorage.getItem('token')) {
  createRoot()

} else {
  const api = new ApiService();
  api.getToken('ANONYMOUS').then(() => createRoot())
}

export function openToast(message: string) {
  toast(message, {
    closeButton: false,
    position: 'top-center',
    closeOnClick: true,
    hideProgressBar: true,
    transition: Slide,
    style: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: '#FFF'
    }
  });
}

function errorHandler(error: any) {
  if ([403, 404].includes(error.status)) {
    window.location.href = '/' + error.status;
    return;
  }
  
  openToast(error.status + ' ' + error.statusText);
}

function createRoot() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  const queryCache = new QueryCache({
    onError: (error: any) => errorHandler(error)
  })

  const mutationCache = new MutationCache({
    onError: (error: any) => errorHandler(error)
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retryDelay: Infinity,
        retry: 0
      }
    }, 
    queryCache,
    mutationCache
  })
  
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Root/>
          <ToastContainer limit={1} />
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}


function Root() {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const isLoading = !!(isFetching || isMutating);

  return (
    <>
      { isLoading && <ProgressContainer /> }
      <RouterProvider router={createBrowserRouter([{
        path: '/',
        element: <RootRoot />,
        children: router
      }])} />
    </>
  );
}

const CustomListItem = styled(ListItem)({
  '& .Mui-selected': {
    color: theme.palette.primary.main,
    '& .MuiTypography-body1': {
      fontWeight: 600
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    }
  }
});

const ToolbarH1 = styled('h1')({
  color: '#5800a8',
  fontWeight: 700,
  fontSize: 32,
  margin: '0 auto',
  cursor: 'pointer',
  zIndex: 10
});

function RootRoot() {
  const navigate = useNavigate();
  const location = useLocation();

  const drawer = (
    <div>
      <svg style={{display: 'none'}}>
        <symbol id="gift" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M131.255167 466.130909l766.242591 0 0 535.731278-766.242591 0 0-535.731278Z" fill="#9FDBAD" />
            <path d="M873.446254 487.415426a2.341297 2.341297 0 0 1 2.341297 2.341297V979.087755a2.341297 2.341297 0 0 1-2.341297 2.341297H154.88098a2.341297 2.341297 0 0 1-2.341296-2.341297V489.543877a2.341297 2.341297 0 0 1 2.341296-2.341296H873.446254m0-42.569033H154.88098A44.91033 44.91033 0 0 0 109.970651 489.543877v489.543878a44.91033 44.91033 0 0 0 44.910329 44.910329H873.446254A44.91033 44.91033 0 0 0 918.782274 979.087755V489.543877A44.91033 44.91033 0 0 0 873.446254 444.846393z" fill="#5C2D51" />
            <path d="M64.634631 289.895113l893.949689 0 0 188.155126-893.949689 0 0-188.155126Z" fill="#FDCA89" />
            <path d="M937.299804 311.17963v145.586092H85.919147v-145.586092h851.380657m5.533974-42.569033H80.385173A37.035059 37.035059 0 0 0 43.350114 305.645656v156.441195a37.035059 37.035059 0 0 0 37.035059 37.035059h862.448605A37.035059 37.035059 0 0 0 979.868836 462.299696v-156.65404a37.035059 37.035059 0 0 0-37.035058-37.035059z" fill="#5C2D51" />
            <path d="M584.189676 283.509759a166.657763 166.657763 0 0 1-98.12162-25.96711C532.893992 177.938557 648.681761 21.284516 749.996059 21.284516a106.422582 106.422582 0 0 1 75.560033 33.629536c24.264349 23.200123 49.167233 60.022336 31.28824 104.719821-24.690039 61.512252-126.217182 123.875885-272.44181 123.875886z" fill="#F05071" />
            <path d="M749.996059 42.569033a86.627982 86.627982 0 0 1 60.660872 27.669871c42.569033 40.866272 30.436858 71.30313 26.3928 81.306853-21.284516 53.424136-117.277685 110.46664-252.860055 110.46664a153.248518 153.248518 0 0 1-68.110452-12.557865C565.884992 171.766047 666.135065 42.569033 749.996059 42.569033m0-42.569033c-146.224628 0-292.874946 264.779384-292.874946 264.779384a201.990061 201.990061 0 0 0 127.707099 40.014891c222.210351 0 380.992844-145.586092 255.414197-265.205074A127.707098 127.707098 0 0 0 749.996059 0z" fill="#5C2D51" />
            <path d="M490.324959 268.610597h42.569033v754.536107h-42.569033z" fill="#5C2D51" />
            <path d="M511.609475 276.698713C262.367788 274.783107 163.820477 193.901944 155.519516 124.514421 149.772696 80.029782 182.763697 40.227736 234.910762 27.669871a175.384415 175.384415 0 0 1 41.079117-5.108284c138.136511 0 212.845164 184.749602 235.619596 254.137126z" fill="#F05071" />
            <path d="M275.777034 44.058949c109.402414 0 176.448641 133.879608 206.034118 211.355248C253.853982 246.687545 182.338007 168.99906 176.804032 121.960279c-4.044058-33.416691 21.284516-63.853549 63.85355-73.431582a154.312744 154.312744 0 0 1 36.183677-4.469748m0-42.569033a195.817551 195.817551 0 0 0-47.251626 5.533974C71.871366 45.123175 69.95576 297.98323 524.805876 297.98323h16.176232S464.357849 1.489916 275.777034 1.489916z" fill="#5C2D51" />
        </symbol>
      </svg>
      <Toolbar>
        <ToolbarH1 onClick={() => navigate('/')}>Хочу и точка!</ToolbarH1>
        <svg style={{
          position: 'absolute',
          top: '12px',
          left: '-4px',
          transform: 'rotate(44deg)',
          width: '32px',
          height: '32px'
        }}><use xlinkHref="#gift"></use></svg>
      </Toolbar>
      <Divider />
      <List>
        <CustomListItem key={'/'} disablePadding>
          <ListItemButton
            disableRipple
            selected={'/' === location.pathname}
            component={Link}
            to="/"
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Мои желания'} />
          </ListItemButton>
        </CustomListItem>

        <CustomListItem key={'/collections'} disablePadding>
          <ListItemButton
            disableRipple
            selected={'/collections' === location.pathname}
            component={Link}
            to="/collections"
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={'Коллекции'} />
          </ListItemButton>
        </CustomListItem>
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '300px' },
        }}
        open
      >
        {drawer}
      </Drawer>
      <Box sx={{marginLeft: { xs: '0', md: '300px' }}}>
        <Outlet />
      </Box>
    </>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
