import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';


const HText = styled('h1')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main
}))

function ErrorPage(props: {code: number}) {
  const { code } = props;

  return (
    <Box>
        {
          code === 403 &&
          <HText>403 Нет доступа</HText>
        }
        {
          code === 404 &&
          <HText>404 Страница не найдена</HText>
        }
    </Box>
  );
}

export default ErrorPage;